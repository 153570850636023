// import logo from "./logo.svg";
import "./App.css";
import { useEffect, useState,useRef } from "react";
import futbol from "./img/futbol.jpg";
import vol from "./img/voleybol.jpg";
import basget from "./img/basget.jpg";
import voleybol_banner from "./img/voleybol-banner.png";
import futbol_banner from "./img/futbol-banner.png";
import bg from "./img/logo_bg.svg";
import logo from "./img/logo.svg";
import logo_black from "./img/logo_black.svg";
import nobg from "./img/logo-nobg.svg";
import banner from "./img/banner-logo.png";
import motor from "./img/motor.jpg";
import profesyonel from "./img/profesyonel.jpg";
import yetenek from "./img/yetenek.jpg";
import espor from "./img/espor.jpg";
import 'animate.css';
import 'intersection-observer';
function App() {
  
  const [open, setOpen] = useState(false);
  const [openMobilDropdown, setOpenMobileDropdown] = useState(false);
  
  const elementsRef = useRef([]);
  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'auto' });
    }
  };
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate__fadeInUp');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    elementsRef.current.forEach((element) => {
      if (element) observer.observe(element);
    });

    return () => {
      if (elementsRef.current) {
        elementsRef.current.forEach((element) => {
          if (element) observer.unobserve(element);
        });
      }
    };
  }, []);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div>
      <header class="bg-transparent" style={{position: 'relative', zIndex: 1}}>
        <nav class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8 w-full" aria-label="Global">
          <div class="flex lg:hidden w-full justify-between">
            <div></div>
            <a href="#" class="-m-1.5 p-1.5">
              <span class="sr-only">ScoutsX</span>
              <img class="h-16 w-auto w-full" src={logo} alt="" />
            </a>
            <button type="button" onClick={() => setOpen(!open)} class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700">
              <span class="sr-only">Open main menu</span>
              <svg class="h-6 w-6" fill="#fff" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
            </button>
          </div>
          <div class="hidden flex flex-row justify-between lg:flex w-full">
              <a href="#" class="-m-1.5 p-1.5">
                <span class="sr-only">ScoutsX</span>
                <img class="h-24 w-auto w-full" src={logo} alt="" />
              </a>
              <div className="flex gap-x-12 items-center">
                <a style={{cursor: 'pointer'}} onClick={() => scrollToElement("b2b")} class="text-lg font-semibold leading-6 text-white flex items-center">Profesyoneller İçin</a>
                <a style={{cursor: 'pointer'}} onClick={() => scrollToElement("b2c")} class="text-lg font-semibold leading-6 text-white flex items-center">Yetenekler İçin</a>
                <a href="mailto:info@scoutsx.com" class="text-lg font-semibold leading-6 text-white flex items-center">İletişim</a>
                <a className="login-btn text-white text-lg" target="_blank" href="https://scoutsx.app">Giriş Yap</a>
              </div>

            {/* <div class="flex gap-x-12 w-2/6">
              <div className='flex gap-x-12'>
                <a style={{cursor: 'pointer'}} onClick={() => scrollToElement("b2b")} class="text-sm font-semibold leading-6 text-white flex items-center">B2B</a>
                <a style={{cursor: 'pointer'}} onClick={() => scrollToElement("b2c")} class="text-sm font-semibold leading-6 text-white flex items-center">B2C</a>
              </div>
            </div>
            <div class="flex w-2/6 lg:flex-1 items-center justify-center">
              <a href="#" class="-m-1.5 p-1.5">
                <span class="sr-only">ScoutsX</span>
                <img class="h-24 w-auto w-full" src={logo} alt="" />
              </a>
            </div>
            <div className='w-2/6 flex justify-end'>
              <a href="#" class="text-sm font-semibold leading-6 text-white flex items-center">İLETİŞİM</a>
            </div> */}
          </div>
        </nav>

        <div class={open == false ? "lg:hidden hidden" : "lg:hidden"} role="dialog" aria-modal="true">
          <div class="fixed inset-0 z-10"></div>
          <div class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div class="flex items-center justify-between">
              <div></div>
              <a href="#" class="-m-1.5 p-1.5">
                <span class="sr-only">ScoutsX</span>
                <img class="h-16 w-auto w-full" src={logo_black} alt="" />
              </a>  
              <button type="button" onClick={() => setOpen(!open)} class="-m-2.5 rounded-md p-2.5 text-gray-700">
                <span class="sr-only">Close menu</span>
                <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div class="mt-6 flow-root">
              <div class="-my-6 divide-y divide-gray-500/10">
                <div class="space-y-2 py-6 text-center flex flex-col items-center">
                  <a onClick={() => [scrollToElement("b2b"),setOpen(!open)]} class="-mx-3 text-lg block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Profesyoneller İçin</a>
                  <a onClick={() => [scrollToElement("b2c"),setOpen(!open)]} class="-mx-3 text-lg block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Yetenekler İçin</a>
                  <a href="mailto:info@scoutsx.com" class="-mx-3 text-lg block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">İletişim</a>
                  <a target="_blank" className="login-btn-black text-base text-lg text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" href="https://scoutsx.app">Giriş Yap</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <main className="min-h-screen ">
        {/* <div className="bg-special">
          <img className="rotating" src={nobg} />
        </div> */}
        <div className='container-full'>
          <div className="home-bg ">
            <div className="flex justify-center items-center flex-col text-area ">
              <div className="home-text animate__animated animate-on-scroll opacity-0 transition-all duration-300 scale-[0.97] mx-auto  max-w-7xl  py-6 lg:px-8 px-4 w-full text-white text-2xl md:text-5xl 2xl:text-6xl lg:text-6xl"
                ref={(el) => (elementsRef.current[0] = el)}
              >Yeteneğini{" "}
              <span className="bold">Scoutsx</span> <span className="light-bold">ile</span>
              <br />
              profesyonel kulüplere taşı!
              </div>
              <div className="home-text-2 text-md md:text-2xl lg:text-2xl animate__animated animate-on-scroll opacity-0 transition-all duration-300 scale-[0.97] mx-auto max-w-7xl lg:px-8 px-4 w-full text-white"
                ref={(el) => (elementsRef.current[1] = el)}
              >
                {screenWidth < 550 ? (
                  <>
                    Scoutsx, amatör sporcuları profesyonel <br /> takımlarla buluşturur. 
                    Profilini <span className="bold">OLUŞTUR,</span><br /> yeteneklerini 
                    <span className="bold"> SERGİLE,</span> kulüplerle <span className="bold">EŞLEŞ.</span>
                  </>
                ) : (
                  <>
                    Scoutsx, amatör sporcuları profesyonel takımlarla buluşturur. <br />
                    Profilini <span className="bold">OLUŞTUR,</span> yeteneklerini 
                    <span className="bold"> SERGİLE,</span> kulüplerle <span className="bold">EŞLEŞ.</span>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className=" animate__animated animate-on-scroll opacity-0 transition-all duration-300 scale-[0.97] section-text-color mx-auto flex md:flex-row flex-col max-w-7xl items-center justify-between p-6 lg:px-8 w-full mt-24"
             ref={(el) => (elementsRef.current[2] = el)}
          >
            <div className="md:w-3/6 w-full">
              <img className="section-img" src={futbol} alt="home"/>
            </div>
            <div className="md:w-3/6 h-full right-section flex flex-col">
              <span className="section-span mb-6">Futbol</span>
              <h1 className="section-title mb-4"> Scoutsx ile sahaya çık ve fark yarat!</h1>
              <p className="text-grey-800">
                Futbol yeteneklerini dünyaya gösterme zamanı. Scoutsx, seni dünya çapında profesyonel kulüplerle
                eşleştirir. Hayalindeki takımla tanış ve yolculuğuna başla !
              </p>
            </div>
          </div>
          <div className=" animate__animated animate-on-scroll opacity-0 transition-all duration-300 scale-[0.97] section-text-color mx-auto flex md:flex-row flex-col max-w-7xl items-center justify-between p-6 lg:px-8 w-full mt-24"
             ref={(el) => (elementsRef.current[3] = el)}
          >
            <div className="md:w-3/6 h-full left-section flex flex-col">
              <span className="section-span mb-6">Basketbol</span>
              <h1 className="section-title mb-4"> Potada ilerlemek için doğru zaman!</h1>
              <p className="text-grey-800">
                Scoutsx, basketbol yeteneklerini global takımlarla eşleştirir. Profili oluştur, yeteneğini göster ve
                kariyerine yön ver.
              </p>
            </div>
            <div className="md:w-3/6 w-full">
              <img className="section-img" src={basget} alt="home"/>
            </div>
          </div>
          <div className="animate__animated animate-on-scroll opacity-0 transition-all duration-300 scale-[0.97] section-text-color mx-auto flex md:flex-row flex-col max-w-7xl items-center justify-between p-6 lg:px-8 w-full mt-24"
             ref={(el) => (elementsRef.current[4] = el)}
          >
            <div className="md:w-3/6 w-full">
              <img className="section-img" src={vol} alt="home"/>
            </div>
            <div className="md:w-3/6 h-full right-section flex flex-col">
              <span className="section-span mb-6">Voleybol</span>
              <h1 className="section-title mb-4">Oyununu bir üst seviyeye taşımanın zamanı</h1>
              <p className="text-grey-800">
                Keşfedilmeyi bekleme. Scoutsx, global voleybol klüpleriyle seni eşleştirir. Profilini oluştur,
                yeteneklerini sergile ve kariyerine yön ver.
              </p>
            </div>
          </div>
          <div className=" animate__animated animate-on-scroll opacity-0 transition-all duration-300 scale-[0.97] section-text-color mx-auto flex md:flex-row flex-col max-w-7xl items-center justify-between p-6 lg:px-8 w-full mt-24"
             ref={(el) => (elementsRef.current[5] = el)}
          >
            <div className="md:w-3/6 h-full left-section flex flex-col">
              <div className="flex flex-row gap-x-4">
                <span className="section-span mb-6">Motor Sporları</span>
                <span className="coming-soon mb-6">Çok Yakında!</span>
              </div>
              <h1 className="section-title mb-4">Pistte Parlama Zamanı!</h1>
              <p className="text-grey-800">
                Motor sporları yeteneklerini dünya çapındaki takımlarla buluşturuyoruz. Scoutsx ile profili oluştur, hızını ve yeteneğini göster, kariyerine yön ver.
              </p>
            </div>
            <div className="md:w-3/6 w-full">
              <img className="section-img" src={motor} alt="home"/>
            </div>
          </div>
          <div className="animate__animated animate-on-scroll opacity-0 transition-all duration-300 scale-[0.97] section-text-color mx-auto flex md:flex-row flex-col max-w-7xl items-center justify-between p-6 lg:px-8 w-full mt-24"
             ref={(el) => (elementsRef.current[6] = el)}
          >
            <div className="md:w-3/6 w-full">
              <img className="section-img" src={espor} alt="home"/>
            </div>
            <div className="md:w-3/6 h-full right-section flex flex-col">
              <div className="flex flex-row gap-x-4">
                <span className="section-span mb-6">E-Spor</span>
                <span className="coming-soon mb-6">Çok Yakında!</span>
              </div>
              <h1 className="section-title mb-4">Ekranda Zafer Zamanı!</h1>
              <p className="text-grey-800">
                Scoutsx, espor yeteneklerini global takımlarla eşleştirir. Profili oluştur, stratejini ve yeteneğini göster, kariyerine yön ver.
              </p>
            </div>
          </div>
          <div className="animate__animated animate-on-scroll opacity-0 transition-all duration-300 scale-[0.97] mx-auto banner-area-2 mt-24 flex flex-col justify-center items-center p-6"
             ref={(el) => (elementsRef.current[7] = el)}
          >
            <div className="flex flex-row justify-center items-center w-full md:gap-x-4 gap-x-2">
              <h1 className="text-white/[0.9] text-center mb:text-6xl text-xl">Keşfedilmeyi bekleme, profesyonel takımlarda sınırlarını keşfet</h1>
            </div>
          </div>
          <div id="b2b" className="animate__animated animate-on-scroll opacity-0 transition-all duration-300 scale-[0.97] section-text-color mx-auto flex md:flex-row flex-col max-w-7xl items-center justify-between p-6 lg:px-8 w-full mt-24"
             ref={(el) => (elementsRef.current[8] = el)}
          >
            <div className="md:w-3/6 w-full">
              <img className="section-img" src={profesyonel} alt="home"/>
            </div>
            <div className="md:w-3/6 h-full right-section flex flex-col">
              <span className="section-span mb-6">Profesyoneller İçin</span>
              <h1 className="section-title mb-4">Tek platformda en iyi yeteneklere ulaşın ve takımınıza değer katın.</h1>
              <p className="text-grey-800">
                Scoutsx, zaman ve maliyet tasarrufu sağlar. Yetenekleri hızlıca değerlendirin, kapsamlı aramalar
                yapın ve zorlu seçim süreçlerini basitleştirin. Daha az zaman ve kaynak harcayın, daha iyi sonuçlar
                elde edin.
              </p>
            </div>
          </div>
          <div id="b2c" className="animate__animated animate-on-scroll opacity-0 transition-all duration-300 scale-[0.97] section-text-color mx-auto flex md:flex-row flex-col max-w-7xl items-center justify-between p-6 lg:px-8 w-full mt-24"
             ref={(el) => (elementsRef.current[9] = el)}
          >
            <div className="md:w-3/6 h-full left-section flex flex-col">
              <span className="section-span mb-6">Yetenekler İçin</span>
              <h1 className="section-title mb-4">Hayalindeki Takımla Buluşma Zamanı!</h1>
              <p className="text-grey-800">
                Scoutsx, ile yeteneğini hızlıca sergile. Kapsamlı scout ekipleri ve profesyonel kulüplerle buluş,
                hayalindeki takımda kariyerine hız kazandır.
              </p>
            </div>
            <div className="md:w-3/6 w-full">
              <img className="section-img" src={yetenek} alt="home"/>
            </div>
          </div>
          <div className="animate__animated animate-on-scroll opacity-0 transition-all duration-300 scale-[0.97] mx-auto banner-area-1 mt-24 flex flex-col justify-center items-center p-6"
             ref={(el) => (elementsRef.current[10] = el)}
          >
            <div className="flex flex-row justify-center items-center w-full md:gap-x-4 gap-x-2">
              <h1 className="text-white/[0.9] mb:text-6xl text-center text-xl">Profesyonel spor dünyasına açılan kapı. Profilini oluştur, performansını sergile, kulüplerle buluş</h1>
            </div>
          </div>
        </div>
      </main>

      <footer className="text-neutral-400 p-4 md:text-center text-start pt-24">
        <div className="contairner mx-auto max-w-7xl">
          <div className='flex md:flex-row flex-row mx-auto flex md:items-center items-center justify-between md:p-6 md:px-8 w-full'>
            <a >
              <img className="h-16 w-auto w-full" src={logo} alt="logo"/>
            </a>
            <div className='flex md:flex-row text-lg flex-col md:gap-x-4 gap-y-1'>
              <a style={{cursor: 'pointer'}} onClick={() => scrollToElement("b2b")}>Profesyoneller İçin</a>
              <a style={{cursor: 'pointer'}} onClick={() => scrollToElement("b2c")}>Yetenekler İçin</a>
              <a href="mailto:info@scoutsx.com">İletişim</a>
            </div>
          </div>
          <div className='mt-8 border-t-2 md:mx-8 '>
            <div className='mt-4 flex justify-between md:flex-row flex-col'>
              <a className="md:mb-4">2024 - ScoutsX. All rights reserved. Created by EAK-OBA</a>
              <a href="">Go to the top</a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
